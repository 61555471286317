<template>
  <section class="bg-grey">
    <div class="container">
      <div class="row">
        <div class="col-12 my-4">
          <div class="card disen cardd">
            <div class="card-header bg-light">
              <div class="row justify-content-between align-items-center px-4">
                <h6 class="font-weight-bold mb-0">Productos</h6>
                <router-link
                  to="/admin/ally/new-product/"
                  custom
                  v-slot="{ navigate }"
                >
                  <button
                    type="button"
                    class="btn btn-oblong btn-success"
                    @click="navigate()"
                  >
                    <i class="icon ion-md-add"></i>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-center flex-wrap">
                <div v-if="noProducts()">
                  <a-empty description="No hay productos en esta categoría" />
                </div>
                <Product
                  v-for="product in products()"
                  :key="product.id"
                  :product="product"
                ></Product>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { getProducts } from "../../../router/auth_guard";
export default {
  name: "Inventory",
  setup() {
    const store = useStore();
    function products() {
      return store.getters.products;
    }
    function noProducts() {
      return store.getters.products.length === 0;
    }
    return { products, noProducts };
  },
  beforeRouteEnter: getProducts,
  beforeRouteUpdate: getProducts,
};
</script>

<style scoped src="../styles/style.css"></style>
<style scoped src="../styles/inventory.css"></style>