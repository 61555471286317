<template>
  <button type="button" class="btn btn-oblong btn-info" @click="showModal">
    <i class="icon ion-md-create"></i>
  </button>
  <a-modal
    v-model:visible="visible"
    title="Secciones"
    @ok="handleOk"
    v-if="visible"
    ><template #footer>
      <a-button type="primary" :loading="isLoading()" @click="handleOk"
        >volver</a-button
      >
    </template>
    <a-spin :spinning="isLoading()" class="spin-modal">
      <form @submit.prevent="addSection">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="titlesfont"> Nueva sección:</label>
              <input
                type="text"
                placeholder="Nombre de la sección"
                class="form-control"
                v-model.trim="newSection"
                required
              />
              <div class="form-group mt-4">
                <input
                  type="file"
                  id="img-section"
                  @change="changeImage"
                  size="1"
                  required
                />
              </div>
              <button class="btn btn-oblong btn-success my-2" type="submit">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
      <a-list-item v-for="section in sections()" :key="section.category">
        <template #actions>
          <a-popconfirm
            title="Quieres eliminar esta sección?"
            ok-text="Si"
            cancel-text="No"
            @confirm="removeSection(section.category)"
          >
            <button type="button" class="btn btn-oblong btn-danger my-2">
              Eliminar sección
            </button>
          </a-popconfirm>
        </template>
        <a-list-item-meta>
          <template #title> {{ section.category }} </template>
          <template #avatar>
            <a-avatar :src="section.image" />
          </template>
          <template #extra>
            <img
              width="272"
              alt="logo"
              src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
            />
          </template>
        </a-list-item-meta>
      </a-list-item>
    </a-spin>
  </a-modal>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "Sections",
  setup() {
    const store = useStore();
    const visible = ref(false);
    const newSection = ref("");
    const imageSection = ref(null);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      visible.value = false;
    };
    function sections() {
      return store.getters.user.product_categories;
    }
    function addSection() {
      store.dispatch("addSection", {
        section: newSection.value,
        image: imageSection.value,
      });
      newSection.value = "";
      imageSection.value = null;
      document.getElementById("img-section").value = "";
    }
    function removeSection(section) {
      store.dispatch("removeSection", section);
    }
    function changeImage(e) {
      imageSection.value = e.target.files[0];
    }
    function isLoading() {
      return store.getters.loading;
    }
    return {
      visible,
      newSection,
      imageSection,
      showModal,
      handleOk,
      sections,
      addSection,
      removeSection,
      changeImage,
      isLoading,
    };
  },
};
</script>

<style scoped src="../styles/style.css"></style>
<style scoped src="../styles/order.css"></style>
<style scoped src="../styles/publi.css"></style>