import { render } from "./Sections.vue?vue&type=template&id=23fdb3e0&scoped=true"
import script from "./Sections.vue?vue&type=script&lang=js"
export * from "./Sections.vue?vue&type=script&lang=js"

import "../styles/style.css?vue&type=style&index=0&id=23fdb3e0&scoped=true&lang=css"
import "../styles/order.css?vue&type=style&index=1&id=23fdb3e0&scoped=true&lang=css"
import "../styles/publi.css?vue&type=style&index=2&id=23fdb3e0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-23fdb3e0"

export default script