import { notification } from 'ant-design-vue'
export const shared = {
    state: {
        loading: false,
        error: null,
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setError(state, error) {
            state.error = error
        },
        clearError(state) {
            state.error = null
        }
    },
    actions: {
        clearError({ commit }) {
            commit('clearError')
        },
        setError({ commit }, error) {
            commit('setError', error)
            notification.error({ message: error.message })
        },
        notificate(o, message) {
            notification.success({ message })
        }
    },
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        }
    }
}

