import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import store from '../store'
import moment from "moment";

export const isNotLogged = (to, from, next) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        // user.getIdTokenResult().then((u) => {
        //     u.claims.admin
        // })
        unsubscribe();
        if (!user) {
            next()
        } else {
            next('/admin')
        }
        store.dispatch("autoSignIn", user);
    });

}

export const isLogged = (to, from, next) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
        // user.getIdTokenResult().then((u) => {
        //     u.claims.admin
        // })
        unsubscribe();
        await store.dispatch("autoSignIn", user);
        const u = await firebase.firestore().collection('users').doc(user.uid).get();
        if (user) {
            if (u.data().accepted && !u.data().bloqued || u.data().superadmin) {
                next()
            } else {
                next('/no_privileges')
            }
        } else {
            next('/login')
        }
    });
}

export const isNotPrivileges = (to, from, next) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
        unsubscribe();
        await store.dispatch("autoSignIn", user);
        const u = await firebase.firestore().collection('users').doc(user.uid).get();
        if (user) {
            if (!u.data().accepted || u.data().bloqued) {
                next()
            } else {
                next('/admin')
            }
        } else {
            next('/login')
        }
    });
}

export const userRol = (to, from, next) => {
    switch (store.getters.user.rol) {
        case 'ally':
            if (to.fullPath.includes('/admin/ally')) next()
            else
                next('/admin/ally')
            break;
        case 'delivery':
            if (to.fullPath.includes('/admin/delivery')) next()
            else
                next('/admin/delivery')
            break;
        default:
            if (to.fullPath === '/admin')
                next()
            else (next('/admin'))
            break;
    }
}

export const isSuperAdmin = (to, from, next) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
        unsubscribe();
        const u = await firebase.firestore().collection('users').doc(user.uid).get();
        if (u.data().superadmin) next()
        else next('/')
        // user.getIdTokenResult().then((u) => {
        //     u.claims.admin
        //     unsubscribe();
        //     if (u.claims.admin) {
        //         next()
        //     } else {
        //         next('/')
        //     }
        // })
    });
}

export const getProducts = async (to, from, next) => {
    await store.dispatch('getProducts', to.params.category);
    next();
}

export const getProduct = async (to, from, next) => {
    await store.dispatch('getProduct', to.params.id);
    next();
}

export const productBlank = async (to, from, next) => {
    await store.commit('setProduct', null);
    next();
}

export const getRequests = async (to, from, next) => {
    await store.dispatch('getRequests');
    next();
}

export const getRequest = async (to, from, next) => {
    await store.dispatch('getRequest', to.params.uid);
    next();
}

export const getHistory = async (to, from, next) => {
    const date = moment();
    await store.dispatch('getHistory', date);
    next();
}

export const getHistoryByAlly = async (to, from, next) => {
    const date = moment();
    await store.dispatch('getHistoryByAlly', date);
    if (next != null && next != undefined) next();
}