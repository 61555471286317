import { createRouter, createWebHistory } from 'vue-router'
import {
  isLogged,
  isNotLogged,
  isNotPrivileges,
  userRol,
  getProduct,
  productBlank,
  isSuperAdmin
} from './auth_guard'
const Admin = () => import('@/modules/admin/views/Admin.vue')
const User = () => import('@/modules/admin/views/User.vue')
const Ally = () => import('@/modules/admin/views/Ally.vue')
const Delivery = () => import('@/modules/admin/views/Delivery.vue')
const Inventory = () => import('@/modules/admin/components/Inventory.vue')
const NewEditProduct = () => import('@/modules/admin/components/NewEditProduct.vue')
const Login = () => import('@/modules/auth/views/Login.vue')
const NoPrivileges = () => import('@/modules/auth/views/NoPrivileges.vue')
const SuperAdmin = () => import('@/modules/admin/views/SuperAdmin.vue')
const SalesHistory = () => import('@/modules/admin/components/SalesHistory.vue')
const Requests = () => import('@/modules/admin/components/Requests.vue')
const EditUser = () => import('@/modules/admin/components/EditUser.vue')
const Profile = () => import('@/modules/admin/views/Profile.vue')
const SalesHistoryByAlly = () => import('@/modules/admin/components/SalesHistoryByAlly.vue')

const routes = [
  { path: '/', redirect: '/admin', },
  {
    path: '/no_privileges',
    name: 'no_privileges',
    component: NoPrivileges,
    beforeEnter: isNotPrivileges,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: isLogged,
    children: [
      { path: '', component: User, beforeEnter: userRol },
      {
        path: 'ally', component: Ally, beforeEnter: userRol, children: [
          { path: '', redirect: '/admin/ally/inventory' },
          {
            path: 'inventory',
            component: Inventory,
            children: [
              {
                path: '',
                component: Inventory,
              },
              {
                path: ':category',
                component: Inventory,

              },
            ]
          },
          {
            path: 'history',
            component: SalesHistoryByAlly,
          },
          {
            path: 'edit-product/:id',
            component: NewEditProduct,
            beforeEnter: getProduct,
          },
          {
            path: 'new-product',
            component: NewEditProduct,
            beforeEnter: productBlank,
          },
          {
            path: 'edit-profile',
            component: Profile
          },
        ],
      },
      {
        path: 'delivery', component: Delivery, beforeEnter: userRol
      },
      {
        path: 'super',
        component: SuperAdmin,
        beforeEnter: isSuperAdmin,
        children: [
          {
            path: '',
            redirect: '/admin/super/requests',
          },
          {
            path: 'requests', component: Requests,
          },
          {
            path: 'requests/:uid',
            component: EditUser,
          },
          {
            path: 'sales-history', component: SalesHistory,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: isNotLogged,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
