import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

let firestoreUserUnsubscribe = null;

export const authStore = {
    state: () => ({
        user: null,
        error: null,
    }),
    mutations: {
        setUser(state, val) {
            state.user = val
        },
    },
    actions: {
        async login({ commit, dispatch }, data) {
            try {
                commit('setLoading', true)
                commit('clearError')
                const user = await firebase.auth().signInWithEmailAndPassword(data.email, data.password)
                dispatch('getUserData', user.user.uid)
                commit('setLoading', false)
            } catch (error) {
                commit('setLoading', false)
                dispatch('setError', error)
            }
        },
        async resetPassword({ commit, dispatch }, data) {
            try {
                commit('setLoading', true)
                commit('clearError')
                await firebase.auth().sendPasswordResetEmail(data.email)
                commit('setLoading', false)
                return true;
            } catch (error) {
                commit('setLoading', false)
                dispatch('setError', error)
                console.log(error)
                return false;
            }
        },
        async logout({ commit, dispatch }) {
            try {
                commit('setLoading', true)
                commit('clearError')
                await firebase.auth().signOut();
                commit('setUser', null)
                commit('setLoading', false)
            } catch (error) {
                commit('setLoading', false)
                dispatch('setError', error)
                console.log(error)

            }
        },
        async autoSignIn({ dispatch }, user) {
            if (user !== null)
                await dispatch('getUserData', user.uid);
        },
        async getUserData({ commit }, userId) {
            return new Promise((resolve, reject) => {
                if (firestoreUserUnsubscribe !== null) firestoreUserUnsubscribe();
                firestoreUserUnsubscribe = firebase.firestore().collection('users').doc(userId).onSnapshot(
                    (userData) => {
                        if (userData.exists) {
                            commit('setUser', { ...userData.data(), 'id': userId })
                        }
                        else commit('setUser', null)
                        resolve();
                    }, () => {
                        reject();
                    }
                );
            })
        }
    },
    getters: {
        user(state) {
            return state.user
        },
    }
}