<template>
  <section class="py-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <h1 class="font-weight-bold mb-0">Bienvenido</h1>

          <p class="lead text-muted colorim">
            La última información a tu disposición
          </p>
        </div>
        <div class="col-lg-3">
          <div class="custom-control custom-switch poss">
            <a-switch :checked="open()" @change="changeOpen"></a-switch>Cerrado
            - Abierto
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- TODO: los datos se deben obtener del historial -->
  <section class="bg-mix">
    <div class="container">
      <div class="card card-bord">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-md-6 d-flex stat my-3">
              <div class="mx-auto">
                <h6 class="text-muted colorim">Ingresos mensuales</h6>
                <h3 class="font-weight-bold">${{ totalPrice() }}</h3>
                <!-- <h6 class="text-success">
                  <i class="icon ion-md-arrow-dropup-circle mr-2"></i>50.50%
                </h6> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6 d-flex stat my-3">
              <div class="mx-auto">
                <h6 class="text-muted colorim">Ingresos de hoy</h6>
                <h3 class="font-weight-bold">${{ todayPrice() }}</h3>
                <!-- <h6 class="text-success">
                  <i class="icon ion-md-arrow-dropup-circle mr-2"></i>30.50%
                </h6> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6 d-flex stat my-3">
              <div class="mx-auto">
                <h6 class="text-muted colorim">Pedidos mensuales</h6>
                <h3 class="font-weight-bold">{{ totalOrders() }}</h3>
                <!-- <h6 class="text-success">
                  <i class="icon ion-md-arrow-dropup-circle mr-2"></i>70.50%
                </h6> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-6 d-flex my-3">
              <div class="mx-auto">
                <h6 class="text-muted colorim">Pedidos de hoy</h6>
                <h3 class="font-weight-bold">{{ todayOrders() }}</h3>
                <!-- <h6 class="text-success">
                  <i class="icon ion-md-arrow-dropup-circle mr-2"></i>80%
                </h6> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { getHistoryByAlly } from "../../../router/auth_guard";
export default {
  name: "Header",
  setup() {
    const store = useStore();
    const state = reactive({
      user: store.getters.user,
      userState: store.getters.user.state,
      history: store.getters.history,
    });
    function open() {
      return store.getters.user.state == "open";
    }
    function changeOpen(v) {
      store.dispatch("changeOpen", v);
    }
    function totalOrders() {
      const h = store.getters.history;
      let total = 0;
      for (let index = 0; index < h.length; index++) {
        const day = h[index];
        total += day.orders.length;
      }
      return total;
    }
    function averageOrders() {
      return (totalOrders() / 30).toFixed(2);
    }
    function todayOrders() {
      const h = store.getters.history;
      const date = moment();
      const d =
        date.year() +
        "-" +
        (date.month() < 10 ? "0" + (date.month() + 1) : date.month() + 1) +
        "-" +
        (date.date() < 10 ? "0" + date.date() : date.date()) +
        " 00:00:00.000Z";
      const today = h.filter((v) => v.day == d);
      return today.length <= 0 ? 0 : today[0].orders.length;
    }
    function totalPrice() {
      const h = store.getters.history;
      let total = 0;
      for (let index = 0; index < h.length; index++) {
        const day = h[index];
        for (let index = 0; index < day.orders.length; index++) {
          const order = day.orders[index];
          total += order.products_price ?? 0;
        }
      }
      return total.toFixed(2);
    }
    function averagePrice() {
      return (totalPrice() / 30).toFixed(2);
    }
    function todayPrice() {
      const h = store.getters.history;
      const date = moment();
      const d =
        date.year() +
        "-" +
        (date.month() < 9 ? "0" + (date.month() + 1) : date.month() + 1) +
        "-" +
        (date.date() < 10 ? "0" + date.date() : date.date()) +
        " 00:00:00.000Z";
      const today = h.filter((v) => v.day == d);
      let total = 0;

      for (let index = 0; index < today.length; index++) {
        const day = today[index];
        for (let index = 0; index < day.orders.length; index++) {
          const order = day.orders[index];
          total += order.products_price ?? 0;
        }
      }
      return total.toFixed(2);
    }
    return {
      ...toRefs(state),
      open,
      changeOpen,
      totalOrders,
      averageOrders,
      todayOrders,
      totalPrice,
      averagePrice,
      todayPrice,
    };
  },
  beforeCreate: () => getHistoryByAlly(),
  // beforeRouteEnter: getHistoryByAlly,
};
</script>

<style scoped src="../styles/style.css"></style>