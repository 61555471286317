<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <a-spin :spinning="isLoading()">
    <router-view />
  </a-spin>
</template>

<script>
import { onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) router.replace("/login");
        else if (route.path == "/login") router.replace("/admin");
        store.dispatch("autoSignIn", user);
      });
    });
    function isLoading() {
      return store.getters.loading;
    }
    return { isLoading };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100vh;
  height: 100vh;
}
.ant-spin-nested-loading > div > .spin-modal {
  max-height: 100%;
  height: 100%;
}
</style>
