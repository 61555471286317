<template>
  <section class="bg-grey">
    <section class="container">
      <div class="row">
        <div class="col-12 my-4">
          <div class="card disen cardd">
            <div class="card-header bg-light">
              <h6 class="font-weight-bold mb-0">
                Ingrese los datos de su producto
              </h6>
            </div>
            <div class="card-body">
              <form @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="titlesfont" for="">
                        Nombre del producto:</label
                      >
                      <input
                        type="text"
                        placeholder="Escriba el nombre del producto"
                        class="form-control"
                        v-model.trim="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mb-10 titlesfont"
                        >Categoria</label
                      >
                      <select
                        class="form-control"
                        data-placeholder="Choose a Category"
                        tabindex="1"
                        v-model.trim="category"
                        required
                      >
                        <option
                          :value="category"
                          v-for="category in categories()"
                          :key="category"
                        >
                          {{ category.toUpperCase() }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="titlesfont" for=""
                        >Cantidad en stock:</label
                      >
                      <input
                        type="number"
                        placeholder="0"
                        class="form-control"
                        v-model.trim="cant"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="form-group">
                      <label class="control-label mb-10 titlesfont"
                        >Elija la sección</label
                      >
                      <select
                        class="form-control"
                        data-placeholder="Choose a Category"
                        tabindex="1"
                        v-model.trim="section"
                        required
                      >
                        <option
                          :value="section.category"
                          v-for="section in sections()"
                          :key="section.category"
                        >
                          {{ section.category.toUpperCase() }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 my-3">
                    <Sections></Sections>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="titlesfont" for="">Precio:</label>
                      <input
                        type="number"
                        step="0.01"
                        pattern="^\d+(?:\.\d{1,2})?$"
                        placeholder="$"
                        class="form-control"
                        v-model.trim="price"
                        required
                      />
                    </div>
                  </div>
                  <!-- <div class="col-md-6"> -->
                  <!-- <div class="form-group">
                      <label
                        class="mr-sm-2 titlesfont"
                        for="inlineFormCustomSelect"
                        >Seleccione o ingrese un descuento para su
                        producto</label
                      >
                      <div class="row">
                        <div class="col-md-6">
                          <select
                            class="custom-select mr-sm-2"
                            id="inlineFormCustomSelect"
                            v-model.trim="discount"
                            placeholder="Personalisado"
                          >
                            <option value="0">Sin descuento</option>
                            <option value="3.03">3.03%</option>
                            <option value="5.2">5.2%</option>
                            <option value="7">7%</option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                            <option value="20">20%</option>
                            <option value="25">25%</option>
                          </select>
                        </div>
                        <div class="col-md-6">
                          <input
                            type="number"
                            step="0.01"
                            pattern="^\d+(?:\.\d{1,2})?$"
                            placeholder="30%"
                            class="form-control"
                            v-model.trim="discount"
                          />
                        </div>
                      </div>
                    </div> -->
                  <!-- </div> -->
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="titlesfont" for=""
                        >Descripción del producto</label
                      >
                      <textarea
                        class="form-control"
                        rows="4"
                        v-model.trim="description"
                        placeholder="Escriba aquí una descripción corta y atractiva de su producto."
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="titlesfont" for="exampleFormControlFile1 "
                      >Suba la imagen de su producto</label
                    >
                    <div class="form-group uploadimag">
                      <div class="card cardimage disen">
                        <img id="img-preview" :src="src" v-if="src" />
                        <div class="card-footer">
                          <input
                            type="file"
                            id="img-uploader"
                            @change="changeImage"
                            size="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <button type="submit" class="btn btn-oblong btn-success">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
export default {
  name: "NewEditProduct",
  setup() {
    const store = useStore();
    // obtener producto por id (debe estar en la url)
    // falta agregar cantidad de productos
    const product = store.getters.product;
    const state = reactive({
      name: product?.name ?? "",
      category: product?.category ?? "",
      section: product?.section ?? "",
      price: product?.price ?? "",
      discount: product?.discount ?? "",
      description: product?.description ?? "",
      image: null,
      cant: product?.cant ?? "",
      src: product?.image ?? "",
    });
    function changeImage(e) {
      state.image = e.target.files[0];
      state.src = URL.createObjectURL(state.image);
    }
    function editProduct() {
      const {
        name,
        cant,
        price,
        discount,
        image,
        category,
        section,
        description,
      } = state;
      store.dispatch("editProduct", {
        name: name ?? product.name,
        allyId: store.getters.user.uid,
        description: description ?? product.description,
        price: !parseFloat(price)
          ? !product.price
            ? 0
            : parseFloat(product.price)
          : parseFloat(price),
        cant: !parseInt(cant)
          ? !product.cant
            ? 0
            : parseInt(product.cant)
          : parseInt(cant),
        discount: !parseFloat(discount)
          ? !product.discount
            ? 0
            : parseFloat(product.discount)
          : parseFloat(discount),
        image: image ?? product.image,
        category: category ?? product.category,
        section: section ?? product.section,
        _geoloc: {
          lat: product._geoloc.lat,
          lng: product._geoloc.lng,
        },
        isNewImage: image !== null,
        id: product.id,
      });
    }
    async function addProduct() {
      const {
        name,
        cant,
        price,
        discount,
        image,
        category,
        section,
        description,
      } = state;
      const { latitude, longitude } = store.getters.user.location;
      await store.dispatch("addProduct", {
        name: name,
        allyId: store.getters.user.uid,
        description: description,
        price: !parseFloat(price) ? 0 : parseFloat(price),
        cant: !parseInt(cant) ? 0 : parseInt(cant),
        discount: !parseFloat(discount) ? 0 : parseFloat(discount),
        image: image,
        category: category,
        section: section,
        _geoloc: {
          lat: latitude,
          lng: longitude,
        },
      });
      state.name = "";
      state.cant = 0;
      state.price = 0;
      state.discount = 0;
      state.category = "";
      state.section = "";
      state.description = "";
    }
    function submit() {
      if (product !== null) editProduct();
      else addProduct();
    }
    function sections() {
      return store.getters.user.product_categories;
    }
    function categories() {
      return store.getters.user.categories;
    }
    return {
      ...toRefs(state),
      addProduct,
      changeImage,
      sections,
      categories,
      editProduct,
      submit,
    };
  },
};
</script>

<style scoped src="../styles/style.css"></style>
<style scoped src="../styles/order.css"></style>
<style scoped src="../styles/publi.css"></style>