import { createApp } from 'vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import App from './App.vue'
import { Spin, Button, Switch, Popconfirm, Modal, Empty, Layout, List, Avatar, Menu, Breadcrumb, Table, DatePicker, Input, Upload, Select, Dropdown } from 'ant-design-vue'
import Navbar from '@/modules/admin/components/Navbar.vue'
import Header from '@/modules/admin/components/Header.vue'
import Inventory from '@/modules/admin/components/Inventory.vue'
import Product from '@/modules/admin/components/Product.vue'
import NewEditProduct from '@/modules/admin/components/NewEditProduct.vue'
import Sections from '@/modules/admin/components/Sections.vue'

// firebase use <project_id>

const firebaseConfig = {
    apiKey: 'AIzaSyBcJImnSA01j0cld3uj1xLJN3PIJGvprrc',
    authDomain: 'tonelaje-3cfb5.firebaseapp.com',
    databaseURL: 'https://tonelaje-3cfb5-default-rtdb.firebaseio.com',
    projectId: 'tonelaje-3cfb5',
    storageBucket: 'tonelaje-3cfb5.appspot.com',
    messagingSenderId: '435841542997',
    appId: '1:435841542997:web:a2cc50cdebae6e64525ddc',
    measurementId: 'G-FW06HDC5VF'
};
// DEV
// const firebaseConfig = {
//     apiKey: "AIzaSyANK7x75lhH_cFPoSAA5Rap2JWhtILMWIs",
//     authDomain: "tonelaje-debug-devs.firebaseapp.com",
//     databaseURL: "https://tonelaje-debug-devs-default-rtdb.firebaseio.com",
//     projectId: "tonelaje-debug-devs",
//     storageBucket: "tonelaje-debug-devs.appspot.com",
//     messagingSenderId: "995231228316",
//     appId: "1:995231228316:web:c3b6de602c3f429a476859",
//     measurementId: "G-ZJ59WC3M13"
// };
firebase.initializeApp(firebaseConfig)
const app = createApp(App)
app.use(Spin)
app.use(Button)
app.use(Switch)
app.use(Popconfirm)
app.use(Modal)
app.use(Empty)
app.use(Layout)
app.use(List)
app.use(Avatar)
app.use(Menu)
app.use(Breadcrumb)
app.use(Table)
app.use(DatePicker)
app.use(Input)
app.use(Upload)
app.use(Select)
app.use(Dropdown)
app.component('Navbar', Navbar)
app.component('Header', Header)
app.component('Inventory', Inventory)
app.component('Product', Product)
app.component('NewEditProduct', NewEditProduct)
app.component('Sections', Sections)
app.use(store).use(router).mount('#app')
