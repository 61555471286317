import axios from 'axios'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import router from '../../../router';

let firestoreProductsUnsubscribe = null;
let firestoreRequestsUnsubscribe = null;
let firestoreHistoryUnsubscribe = null;

export const adminStore = {
    state: () => ({
        products: [],
        product: null,
        requests: [],
        request: null,
        history: [],
    }),
    mutations: {
        setProducts(state, val) {
            state.products = val;
        },
        setProduct(state, val) {
            state.product = val;
        },
        setRequests(state, val) {
            state.requests = val
        },
        setRequest(state, val) {
            state.request = val
        },
        setHistory(state, val) {
            state.history = val;
        },
    },
    actions: {
        async changeOpen({ commit, getters }, data) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(getters.user.id).update({ 'state': data ? 'open' : 'close' });
            } catch (error) {
                commit('setError', error)
            }
        },
        async getProducts({ commit, getters }, category) {
            try {
                commit('setLoading', true)
                commit('clearError')
                if (firestoreProductsUnsubscribe !== null) firestoreProductsUnsubscribe();
                let ref = firebase.firestore().collection('users').doc(getters.user.id).collection('products');
                if (category !== undefined && category !== null)
                    ref = ref.where('section', '==', category);

                firestoreProductsUnsubscribe = ref.onSnapshot((data) => {
                    if (data.empty) {
                        commit('setProducts', [])
                    }
                    else {
                        const products = data.docs.map((p) => {
                            return { ...p.data(), 'id': p.id };
                        })
                        commit('setProducts', products);
                    }
                    commit('setLoading', false)
                })
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async getProduct({ commit, getters }, productId) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                firebase.firestore().collection('users').doc(getters.user.id).collection('products').doc(productId).get().then(data => {
                    if (!data.exists)
                        commit('setProduct', null)
                    else
                        commit('setProduct', { ...data.data(), id: data.id })
                    resolve()
                }, (error) => {

                    commit('setError', error)
                    reject();
                })
            })
        },
        async addSection({ commit, dispatch, getters }, data) {

            if (data.section.trim() === '') return;
            try {
                commit('setLoading', true)
                commit('clearError')
                let contain = false;
                for (let i = 0; i < (getters.user.product_categories?.length ?? 0); i++) {
                    const c = getters.user.product_categories[i];
                    contain = contain || c.category === data.section
                }
                if (contain)
                    dispatch('setError', { message: 'La sección ya existe' })
                else {
                    const form = new FormData();
                    form.append("image", data.image);
                    const res = await axios.post('https://api.imgur.com/3/image', form, { headers: { Authorization: 'Client-ID 41ff5a296a0cb14' } })

                    if (res.status === 200) {
                        const productCategories = [...(getters.user.product_categories ?? []), {
                            category: data.section.toLowerCase(),
                            image: res.data.data.link,
                        }];
                        await firebase.firestore().collection('users').doc(getters.user.id).update({ product_categories: productCategories });
                        dispatch('notificate', 'La sección fue agregada correctamente')
                    }
                }
                commit('setLoading', false)
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async removeSection({ commit, dispatch, getters }, section) {
            try {
                commit('clearError')
                commit('setLoading', true)
                let index = -1;
                let productCategories = getters.user.product_categories;
                for (let i = 0; i < productCategories.length; i++) {
                    const c = productCategories[i];
                    if (c.category === section) index = i;
                }
                if (index != -1) {
                    productCategories.splice(index, 1)
                    await firebase.firestore().collection('users').doc(getters.user.id).update({ product_categories: productCategories });
                    dispatch('notificate', 'La sección fue eliminada')
                }
                commit('setLoading', false)
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async addProduct({ commit, dispatch, getters }, data) {
            commit('setLoading', true)
            try {
                commit('clearError')
                const form = new FormData();
                form.append("image", data.image);
                const res = await axios.post('https://api.imgur.com/3/image', form, { headers: { Authorization: 'Client-ID 41ff5a296a0cb14' } })

                if (res.status === 200) {
                    delete data.image;
                    await firebase.firestore().collection('users').doc(getters.user.id).collection('products').add({ ...data, image: res.data.data.link }).catch(() => {
                        dispatch('setError', { message: 'Ha ocurrido un error, inténtalo más tarde' });
                    });
                    dispatch('notificate', 'Producto agregado correctamente');
                }
                else {
                    dispatch('setError', { message: 'Ha ocurrido un error, inténtalo más tarde' });
                }
                commit('setLoading', false)
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async editProduct({ commit, dispatch, getters }, data) {
            try {
                commit('clearError')
                commit('setLoading', true)
                if (data.isNewImage) {
                    const form = new FormData();
                    form.append("image", data.image);
                    const res = await axios.post('https://api.imgur.com/3/image', form, { headers: { Authorization: 'Client-ID 41ff5a296a0cb14' } })
                    if (res.status === 200) {
                        delete data.image;
                        await firebase.firestore().collection('users').doc(getters.user.id).collection('products').doc(data.id).update({ ...data, image: res.data.data.link });
                        dispatch('notificate', 'Producto editado correctamente')
                    }
                    else {
                        dispatch('setError', { message: 'Ha ocurrido un error, inténtalo más tarde' })
                    }
                } else {
                    await firebase.firestore().collection('users').doc(getters.user.id).collection('products').doc(data.id).update({ ...data });
                    dispatch('notificate', 'Producto editado correctamente')
                }
                commit('setLoading', false)
                commit('setProduct', null)
                router.push('/admin')
            } catch (error) {
                commit('setLoading', false)
                commit('setError', error)
            }
        },
        async removeProduct({ commit, dispatch, getters }, data) {
            try {
                commit('clearError')
                commit('setLoading', true)
                await firebase.firestore().collection('users').doc(getters.user.id).collection('products').doc(data.id).delete();
                dispatch('notificate', 'Producto eliminado')
                commit('setLoading', false)
                commit('setProduct', null)
            } catch (error) {
                commit('setLoading', false)
                commit('setError', error)
            }
        },
        async getRequests({ commit }) {
            try {
                commit('setLoading', true)
                commit('clearError')
                if (firestoreRequestsUnsubscribe !== null) firestoreRequestsUnsubscribe();
                let ref = firebase.firestore().collection('users').where('rol', 'in', ['ally', 'delivery']);

                firestoreRequestsUnsubscribe = ref.onSnapshot((data) => {
                    if (data.empty) {
                        commit('setRequests', [])
                    }
                    else {
                        const requests = data.docs.map((p) => {
                            return { ...p.data(), 'id': p.id, 'key': p.id };
                        })
                        commit('setRequests', requests);
                    }
                    commit('setLoading', false)
                })
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async getRequest({ commit }, uid) {
            try {
                commit('setLoading', true)
                commit('clearError')

                const data = await firebase.firestore().collection('users').doc(uid).get()
                if (!data.exists) {
                    commit('setRequest', null)
                }
                else {
                    const request = { ...data.data(), 'id': data.id };
                    commit('setRequest', request);
                }
                commit('setLoading', false)
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async changeAccepted({ commit }, data) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(data.id).update({ 'accepted': data.accepted });
            } catch (error) {
                commit('setError', error)
            }
        },
        async changeBloqued({ commit }, data) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(data.id).update({ 'bloqued': data.bloqued });
            } catch (error) {
                commit('setError', error)
            }
        },
        async changeBlockPayment({ commit }, data) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(data.id).update({ 'block_payment': data.block_payment });
            } catch (error) {
                commit('setError', error)
            }
        },
        async removeUser({ commit }, id) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(id).delete();
            } catch (error) {
                commit('setError', error)
            }
        },
        async updateUser({ commit }, data) {
            try {
                commit('clearError')
                firebase.firestore().collection('users').doc(data.id).update({ commission: data.commission });
            } catch (error) {
                commit('setError', error)
            }
        },
        async updateProfile({ commit, dispatch }, data) {
            try {
                commit('setLoading', true)
                commit('clearError')
                if (data.image) {
                    const form = new FormData();
                    form.append("image", data.image);
                    const res = await axios.post('https://api.imgur.com/3/image', form, { headers: { Authorization: 'Client-ID 41ff5a296a0cb14' } })

                    if (res.status === 200) {
                        delete data.image
                        await firebase.firestore().collection('users').doc(data.id).update({ ...data, photo: res.data.data.link });
                    }
                } else {
                    await firebase.firestore().collection('users').doc(data.id).update(data);
                }
                dispatch('notificate', 'El perfil fue actualizado')
                commit('setLoading', false)
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async getHistory({ commit }, date) {
            try {
                commit('setLoading', true)
                commit('clearError')
                commit('setHistory', [])
                if (firestoreHistoryUnsubscribe !== null) firestoreHistoryUnsubscribe();
                let ref = firebase.firestore().collectionGroup('history').where('date', '==', date.year() +
                    "-" +
                    (date.month() < 9 ? "0" + (date.month() + 1) : (date.month() + 1)) +
                    "-01 00:00:00.000Z");
                firestoreHistoryUnsubscribe = ref.onSnapshot((data) => {
                    if (data.empty) {
                        commit('setHistory', [])
                    }
                    else {
                        const history = data.docs.map((p) => {
                            return { ...p.data(), 'id': p.id, 'key': p.id };
                        })
                        commit('setHistory', history);
                    }
                    commit('setLoading', false)
                })
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
        async getHistoryByAlly({ commit, getters }, date) {
            try {
                commit('setLoading', true)
                commit('clearError')
                commit('setHistory', [])
                if (firestoreHistoryUnsubscribe !== null) firestoreHistoryUnsubscribe();
                let ref = firebase.firestore().collection('users').doc(getters.user.id).collection('history').where('date', '==', date.year() +
                    "-" +
                    (date.month() < 10 ? "0" + (date.month() + 1) : (date.month() + 1)) +
                    "-01 00:00:00.000Z");

                firestoreHistoryUnsubscribe = ref.onSnapshot((data) => {
                    if (data.empty) {
                        commit('setHistory', [])
                    }
                    else {
                        const history = data.docs.map((p) => {
                            return { ...p.data(), 'id': p.id, 'key': p.id };
                        })
                        commit('setHistory', history);
                    }
                    commit('setLoading', false)
                })
            } catch (error) {
                commit('setError', error)
                commit('setLoading', false)
            }
        },
    },
    getters: {
        products(state) {
            return state.products;
        },
        product(state) {
            return state.product;
        },
        requests(state) {
            return state.requests;
        },
        request(state) {
            return state.request;
        },
        history(state) {
            return state.history;
        },
        ordersFromHistory(state) {
            let orders = [];
            for (let index = 0; index < state.history.length; index++) {
                const day = state.history[index];
                for (let index = 0; index < day.orders.length; index++) {
                    const order = day.orders[index];
                    orders = [
                        ...orders,
                        { ...order, key: day.key + index, day: day.day },
                    ];
                }
            }
            return orders;
        }
    }
}