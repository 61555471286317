<template>
  <div class="card border-0 col-12 col-md-6 col-lg-4 col-xl-4 mb-4">
    <div class="card-body cardin">
      <img :src="productData().image" class="img-fluidd" alt="" />
      <h5 class="card-title">{{ productData().name }}</h5>
      <p class="card-text pma">
        <small class="text-muted"
          >Unidades disponibles: {{ productData().cant }}</small
        >
      </p>
      <span class="tx-18 valign-top">
        <i class="icon ion-md-star"></i>
        <i class="icon ion-md-star"></i>
        <i class="icon ion-md-star"></i>
        <i class="icon ion-md-star"></i>
        <i class="icon ion-md-star"></i>
      </span>
      <div class="container tam" style="overflow: auto">
        <div class="row">
          <p class="card-text">
            {{ productData().description }}
          </p>
        </div>
      </div>
      <div class="mg-t-1">
        <h5 class="tx-normal mg-b-5">
          USD <span class="tx-danger">{{ productData().price }}</span>
        </h5>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-6" style="justify-content: flex-end; display: flex">
            <router-link
              :to="'/admin/ally/edit-product/' + productData().id"
              custom
              v-slot="{ navigate }"
            >
              <button
                type="button"
                class="btn btn-oblong btn-success"
                @click="navigate"
              >
                <i class="icon ion-md-create"></i>
              </button>
            </router-link>
          </div>
          <div class="col-6">
            <a-popconfirm
              title="Quieres eliminar este producto?"
              ok-text="Si"
              cancel-text="No"
              @confirm="removeProduct()"
            >
              <button type="button" class="btn btn-oblong btn-danger">
                <i class="icon ion-md-trash"></i>
              </button>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Product",
  props: ["product"],
  setup(props) {
    const store = useStore();
    function productData() {
      return props.product;
    }
    function removeProduct() {
      store.dispatch("removeProduct", props.product);
    }
    return { productData, removeProduct };
  },
};
</script>

<style scoped src="../styles/style.css"></style>
<style scoped src="../styles/inventory.css"></style>