<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand">TONELAJE</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <a class="nav-link"
              ><i class="icon ion-md-restaurant mr-2"></i>Ordenes</a
            >
          </li> -->
          <li class="nav-item" v-if="user().superadmin">
            <router-link to="/admin/super" class="nav-link">
              <i class="icon ion-md-lock mr-2"></i>
              Super
            </router-link>
          </li>
          <router-link
            to="/admin/ally/history"
            v-slot="{ isActive, navigate }"
            custom
          >
            <a class="nav-link" @click="navigate" :class="{ active: isActive }">
              <i class="icon ion-md-calendar mr-2"></i>
              Historial
            </a>
          </router-link>
          <router-link to="/admin/ally/inventory" v-slot="{ isActive }" custom>
            <a-dropdown>
              <a
                class="ant-dropdown-link dropdown nav-link"
                @click.prevent
                :class="{ active: isActive }"
              >
                <i class="icon ion-md-cube mr-2"></i>
                Inventario
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <router-link
                      to="/admin/ally/inventory"
                      v-slot="{ navigate, href }"
                      custom
                    >
                      <a
                        class="dropdown-item"
                        :href="href"
                        @click.prevent="navigate()"
                        >Todo</a
                      >
                    </router-link>
                  </a-menu-item>
                  <div class="dropdown-divider"></div>
                  <a-menu-item
                    v-for="category in user().product_categories"
                    :key="category.category"
                  >
                    <router-link
                      :to="'/admin/ally/inventory/' + category.category"
                      v-slot="{ navigate, href }"
                      custom
                    >
                      <a
                        class="dropdown-item"
                        :href="href"
                        @click.prevent="navigate()"
                        >{{ category.category }}</a
                      >
                    </router-link>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </router-link>
          <!-- <li class="nav-item">
            <a class="nav-link"
              ><i class="icon ion-md-pizza mr-2"></i>Publicidad</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"
              ><i class="icon ion-md-today mr-2"></i>Control
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><i class="icon ion-md-time mr-2"></i>Horario</a>
          </li> -->

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle ml-4"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="user()"
            >
              <!--rounded-circle va en la class de la imagen-->
              <a-avatar :src="user().photo"> </a-avatar>
              {{ user().username }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/admin/ally/edit-profile/"
                v-slot="{ navigate, href }"
                custom
              >
                <a
                  class="dropdown-item"
                  :href="href"
                  @click.prevent="navigate()"
                >
                  Perfil
                </a>
              </router-link>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click.prevent="logout">Cerrar sesión</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Navbar",
  setup() {
    const store = useStore();
    function user() {
      return store.getters.user;
    }
    function logout() {
      store.dispatch("logout");
    }
    return { user, logout };
  },
};
</script>

<style scoped src="../styles/style.css"></style>