import { render } from "./NewEditProduct.vue?vue&type=template&id=1e060370&scoped=true"
import script from "./NewEditProduct.vue?vue&type=script&lang=js"
export * from "./NewEditProduct.vue?vue&type=script&lang=js"

import "../styles/style.css?vue&type=style&index=0&id=1e060370&scoped=true&lang=css"
import "../styles/order.css?vue&type=style&index=1&id=1e060370&scoped=true&lang=css"
import "../styles/publi.css?vue&type=style&index=2&id=1e060370&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-1e060370"

export default script