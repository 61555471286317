import { createStore } from 'vuex'
import { authStore } from '@/modules/auth/store'
import { shared } from '@/modules/shared/store'
import { adminStore } from '../modules/admin/store'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authStore,
    shared: shared,
    admin: adminStore,
  }
})
